import KanbanIcon from "../main/Icons/kanban.png";
import Analytics from "../main/Icons/analytics.png";
import Maintenance from "../main/Icons/maintenance-analysis-100-white.png";
import MaintenanceManagement from "../main/Icons/maintenance-management-64-white.png";
import Equipment from "../main/Icons/equipment-analysis-100-white.png";
import AssetManagementIcon from "../main/Icons/asset-management-100-white.png";
import Crews from "../main/Icons/crews-100-white.png";
import AssetsIcon from "../main/Icons/Assets-Icon.png";
import PeopleIcon from "../main/Icons/people.png";
import SettingsIcon from "../main/Icons/settings.png";
import StaffIcon from "../main/Icons/person-staff.png";
import WorkRequestIcon from "../main/Icons/work-request-icon-100-white.png";
import navAuthProfiles from "../auth/navAuthProfiles";
import FleetManageIcon from "../main/Icons/icons8-semi-truck-100.png";
import TaskGroupIcon from "../main/Icons/icons8-task-group-100.png";
import ClassificationSchemes from "../main/Icons/classification-schemes-100-white.png";
/*import DOTComplianceIcon from "../main/Icons/icons8-task-100.png";
import IotManagement from "../main/Icons/iot-management.png";
import IotHealth from "../main/Icons/iot-health.png";
import Locations from "../main/Icons/locations.png";
import Networks from "../main/Icons/network.png";
import Gateways from "../main/Icons/gateway.png";
import Sensors from "../main/Icons/sensor.png";
import ActivityManagement from "../main/Icons/activity-management.png";
import MaintenanceSystem from "../main/Icons/fracttal.png";
import Devices from "../main/Icons/devices.png";
import Customs from "../main/Icons/customs.png";
import SystemAnalysis from "../main/Icons/system-status-100-white.png";
import StockAnalysis from "../main/Icons/stock-analysis-100-white.png";
import DocumentManagement from "../main/Icons/document-management-100-white.png";
import FacilitiesIcon from "../main/Icons/facilities-icon.png";
import LifeCycleManagementIcon from "../main/Icons/Life-Cycle-Management.png";
import HSSEIcon from "../main/Icons/HSSE-Icon.png";
import RiskManagementIcon from "../main/Icons/Risk-Management-Icon.png";
import ContractorManagementIcon from "../main/Icons/Contractor-Management-Icon.png";
import ChangeManagementIcon from "../main/Icons/change-management.png";
import StrategyManagementIcon from "../main/Icons/strategy-management.png";
import DocumentsManagementIcon from "../main/Icons/documents-management.png";
import ProjectsIcon from "../main/Icons/projects.png";
import TeamCalendarIcon from "../main/Icons/team-calendar.png";
import CollaborationIcon from "../main/Icons/collaboration.png";
import MaterialsManagement from "../main/Icons/materials-management.png";
import PartsCatalogueIcon from "../main/Icons/parts-catalogue.png";
import StorageLocationsIcon from "../main/Icons/storage-locations.png";
import SuppliesIcon from "../main/Icons/suppliers-icon.png";
import OrderTrackingIcon from "../main/Icons/order-tracking.png";
import PurchaseRequestsIcon from "../main/Icons/purchase-requests.png";
import UserCheckIcon from "../main/Icons/person-confirm.png";
import UserKeyIcon from "../main/Icons/person-key.png";
import PeopleGroupIcon from "../main/Icons/people-group.png";*/

const navigationConfig = [
  {
    id: "analytics",
    title: "Analytics",
    type: "collapse",
    icon: Analytics,
    access: navAuthProfiles.analyticsOnly,
    children: [
      {
        id: "maintenance-analysis",
        title: "Maintenance Analysis",
        type: "item",
        icon: Maintenance,
        access: navAuthProfiles.analyticsOnly,
        url: "/analytics/maintenance-analysis",
      },
      //{
      //    id: "dot-compliance",
      //    title: "DOT Compliance",
      //    type: "item",
      //    icon: DOTComplianceIcon,
      //    access: navAuthProfiles.analyticsOnly,
      //    url: "/analytics/dot-compliance"
      //},
      {
        id: "f3-reporting",
        title: "F3 Reporting",
        type: "item",
        icon: Maintenance,
        access: navAuthProfiles.analyticsOnly,
        url: "/analytics/f3-reporting",
      },
      {
        id: "equipment-analysis",
        title: "Equipment Analysis",
        type: "item",
        icon: Equipment,
        access: navAuthProfiles.analyticsOnly,
        url: "/analytics/equipment-analysis",
      },
      {
        id: "fleet-analysis",
        title: "Fleet analysis",
        type: "item",
        icon: FleetManageIcon,
        access: navAuthProfiles.analyticsOnly,
        url: "/analytics/fleet-analysis",
      },
      /*{
                id: "system-analysis",
                title: "System Analysis",
                type: "item",
                icon: SystemAnalysis,
                access: navAuthProfiles.superAdmin,
                url: "/analytics/system-analysis"
            },
            {
                id: "live-maintenance-analysis",
                title: "Live Maintenance Analysis",
                type: "item",
                icon: Maintenance,
                access: navAuthProfiles.analyticsOnly,
                url: "/analytics/maintenance-analysis-live"
            },
            {
                id: "live-equipment-analysis",
                title: "Live Equipment Analysis",
                type: "item",
                icon: Equipment,
                access: navAuthProfiles.analyticsOnly,
                url: "/analytics/equipment-analysis-live"
            }*/
    ],
  },
  {
    id: "asset-management",
    title: "Asset Management",
    type: "collapse",
    icon: AssetManagementIcon,
    access: navAuthProfiles.assetManager,
    children: [
      {
        id: "assets",
        title: "Assets",
        type: "item",
        icon: AssetsIcon,
        access: navAuthProfiles.assetManager,
        url: "/asset-management/assets",
      },
      /*{
                id: "meters",
                title: "Meters",
                type: "item",
                icon: AssetsIcon,
                access: navAuthProfiles.superAdmin,
                url: "/asset-management/meters"
            },
            {
                id: "document-management",
                title: "Document Management",
                type: "item",
                icon: DocumentManagement,
                access: navAuthProfiles.superAdmin,
                url: "/asset-management/document-management"
            }*/
    ],
  },
  {
    id: "maintenance-management",
    title: "Maintenance Management",
    type: "collapse",
    icon: MaintenanceManagement,
    access: navAuthProfiles.technician,
    children: [
      {
        id: "work-order-kanban",
        title: "Work Orders",
        type: "item",
        icon: KanbanIcon,
        access: navAuthProfiles.manager,
        url: "/maintenance-management/work-order-kanban/boards/1/work-order-kanban",
      },
      {
        id: "work-requests",
        title: "Work Requests",
        type: "item",
        icon: WorkRequestIcon,
        access: navAuthProfiles.technician,
        url: "/maintenance-management/work-requests",
      },
      /*{
                id: "maintenance-plans",
                title: "Maintenance Plans",
                type: "item",
                icon: DocumentManagement,
                access: navAuthProfiles.superAdmin,
                url: "/maintenance-management/maintenance-plans"
            },*/
      {
        id: "people",
        title: "Personnel",
        type: "item",
        icon: StaffIcon,
        access: navAuthProfiles.admin,
        url: "/maintenance-management/people",
      },
      {
        id: "27",
        title: "Operation Groups",
        type: "item",
        icon: TaskGroupIcon,
        access: navAuthProfiles.admin,
        url: "/maintenance-management/operation-groups",
      },
      {
        id: "crews",
        title: "Fleets",
        type: "item",
        icon: Crews,
        access: navAuthProfiles.moveManager,
        url: "/maintenance-management/crews",
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: SettingsIcon,
    access: navAuthProfiles.admin,
    children: [
      // {
      //   id: "classification-schemes",
      //   title: "Classification Schemes",
      //   type: "item",
      //   icon: ClassificationSchemes,
      //   access: navAuthProfiles.superAdmin,
      //   url: "/settings/classification-schemes",
      // },
      {
        id: "users",
        title: "Users",
        type: "item",
        icon: PeopleIcon,
        access: navAuthProfiles.admin,
        url: "/settings/users",
      },
    ],
  },
  /*{
        id: "live",
        title: "Live",
        type: "collapse",
        icon: SettingsIcon,
        access: navAuthProfiles.superAdmin,
        children: [
            {
                id: "users",
                title: "Users",
                type: "item",
                icon: PeopleIcon,
                access: navAuthProfiles.superAdmin,
                url: "/settings/user"
            },
            {
                id: "people",
                title: "Personnel",
                type: "item",
                icon: StaffIcon,
                access: navAuthProfiles.superAdmin,
                url: "/maintenance-management/personnel"
            },
            {
                id: "crews",
                title: "Fleets",
                type: "item",
                icon: Crews,
                access: navAuthProfiles.superAdmin,
                url: "/maintenance-management/crew"
            },
            {
                id: "work-order-board",
                title: "Work Orders",
                type: "item",
                icon: KanbanIcon,
                access: navAuthProfiles.technician,
                url: "/maintenance-management/work-order-board/boards/1/work-order-kanban"
            },
            {
                id: "work-request",
                title: "Work Requests",
                type: "item",
                icon: WorkRequestIcon,
                access: navAuthProfiles.technician,
                url: "/maintenance-management/work-request"
            }
        ]
    }*/
];

export default navigationConfig;
